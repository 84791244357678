@import '../../variables';

.app-signup-variant {
  .app-signup-variant-item {
    width: 100%;
    box-sizing: border-box;
    max-width: 432px;
    border: 2px solid $primary-green-color;
    padding: 22px 16px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    gap: 20px;

    .app-signup-variant-item-body {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 6px;
      font-size: 16px;

      .app-signup-variant-item-title {
        font-weight: 600;
        font-size: 20px;
        color: $primary-green-color;
        display: flex;
        span {
          white-space: nowrap;
        }

        .app-signup-variant-item-tooltip-trigger {
          display: inline-block;
          position: relative;
          transform: translate(3px, 0);
          width: 100%;
          white-space: unset;

          img {
            z-index: -1;
            cursor: pointer;
            width: 19px;
            height: 19px;
            margin: 3px 0 0 5px;
          }

          .app-signup-variant-item-tooltip {
            position: absolute;
            top: 32px;
            left: -38px;
            padding: 26px 14px;
            border: 1px solid $primary-green-color;
            border-radius: 12px;
            box-shadow: 0px 2px 6px #00000029;
            background: white;
            max-width: 373px;
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 19px;
            box-sizing: border-box;

            @media only screen and (min-width: 1423px) {
              width: 373px;
            }

            .app-signup-variant-item-tooltip-arrow {
              position: absolute;
              fill: white;
              width: 20px;
              height: 20px;
              top: -17px;
              left: 42px;
            }

            .app-signup-variant-item-tooltip-description {
              font-size: 16px;
              font-weight: normal;
              color: $primary-black-color2;
            }

            .app-signup-variant-item-tooltip-title {
              font-weight: 600;
              font-size: 20px;
              color: $primary-green-color;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 450px) {

  .app-signup-variant {
    .app-signup-variant-item {
      .app-signup-variant-item-body {
        .app-signup-variant-item-title {
          span {
            white-space: unset;
          }
        }
      }
    }
  }
}
